import { template as template_ac30abba4fb84d5590f7221b1a0cd717 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ac30abba4fb84d5590f7221b1a0cd717(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
