import { template as template_0f61f33b7b8941d68dd724eafc2062d0 } from "@ember/template-compiler";
const FKLabel = template_0f61f33b7b8941d68dd724eafc2062d0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
