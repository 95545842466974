import { template as template_d3905b88ae174533a4b5e42e880fe5a7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d3905b88ae174533a4b5e42e880fe5a7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
