import { template as template_8d6e53bdfd4f4b86a2a98605c82c793b } from "@ember/template-compiler";
const WelcomeHeader = template_8d6e53bdfd4f4b86a2a98605c82c793b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
